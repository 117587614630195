$primary-color: #2196f3;
$primary-color-dark: #0067c0;
$primary-color-light: #6ec4ff;

$background-color: #969696;
$background-color-light: #e6e6e6;

$accent-color: #f37f21;
$accent-color-dark: #ba5100;
$accent-color-light: #ffb053;

$textcolor-light: white;
$textcolor-dark: black;
